import React, { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { hideLoadingOverlay } from './redux/api';
import { LottieLoader } from 'src/components/loading';
import darkLoading from 'src/components/loading/img/dark.json';

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  
  ._loading_overlay_content {
    margin-top: 20%;
    > div {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
  }

  ${props => props.show && css`
    display: block; /* show */
  `}
`;

const MyLoadingOverlay = ({ active = true, children, message, show, spinner = <LottieLoader height={60} src={darkLoading} />, timeOut, callback,hideLoadingOverlay}) => {
  useEffect(() => {
    if(show && timeOut > 0) {
      setTimeout(() => {
        hideLoadingOverlay();
        // call back
        callback();
      }, timeOut);
    }
  }, [show]);

  return (
    <DarkBackground show={show}>
      <LoadingOverlay
        active={active}
        spinner={spinner}
        text={message}
      >
        {children}
      </LoadingOverlay>
    </DarkBackground>
  );
};

const mapState = state => ({
  ...state.loadingOverlayReducer
});

const mapDispatch = {
  hideLoadingOverlay
};

export default connect(mapState, mapDispatch) (MyLoadingOverlay);